import React, { useState } from "react";
import { Button, Offcanvas, Card, Form } from "react-bootstrap";
import { TbMessageReport } from "react-icons/tb";
import * as Dayjs from "dayjs";

const ProblemReport = ({ problems }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Offcanvas
        show={show}
        size="lg"
        placement="end"
        style={{ minWidth: "40%" }}
      >
        <Offcanvas.Header closeButton onHide={() => setShow(false)}>
          <Offcanvas.Title>Problem Reports</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {problems?.map((u, idx) => (
            <Card className="g-4 my-4">
              <Card.Header>
                <Card.Title>
                  {u.user.firstName} {u.user.lastName}
                </Card.Title>
                <Card.Title>
                  {Dayjs(u.createdOn).format("MM/DD/YYYY h:mm A")}
                </Card.Title>
                {u.user.email}
              </Card.Header>
              <Card.Body>
                <Form.Label>Problem Description</Form.Label>
                <Form.Control
                  type="textarea"
                  value={u.userStatement}
                  readOnly
                ></Form.Control>
                <Form.Group className="my-2 mx-4">
                  <Form.Check
                    readOnly
                    checked={u.addressSearch}
                    label="Address"
                  ></Form.Check>
                  <Form.Check
                    readOnly
                    checked={u.electricSearch}
                    label="Electric Providers"
                  ></Form.Check>
                  <Form.Check
                    readOnly
                    checked={u.gasSearch}
                    label="Gas Providers"
                  ></Form.Check>
                  <Form.Check
                    readOnly
                    checked={u.waterSearch}
                    label="Water Providers"
                  ></Form.Check>
                  <Form.Check
                    readOnly
                    checked={u.sewerSearch}
                    label="Sewer Providers"
                  ></Form.Check>
                  <Form.Check
                    readOnly
                    checked={u.telecomSearch}
                    label="Internet Providers"
                  ></Form.Check>
                </Form.Group>
              </Card.Body>
            </Card>
          ))}
        </Offcanvas.Body>
      </Offcanvas>
      <Button
        size="sm"
        title="Problem Report"
        variant="warning"
        disabled={problems?.length < 1}
        style={{
          color: "white",
        }}
        onClick={() => setShow(true)}
      >
        <TbMessageReport />
      </Button>
    </>
  );
};

export default ProblemReport;
