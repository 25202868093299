import { gql } from "@apollo/client";

const ReportProblem = gql`
  mutation CreateReportProblem(
    $searchId: UUID!
    $userId: UUID!
    $statement: String!
    $address: Boolean
    $gas: Boolean
    $water: Boolean
    $electric: Boolean
    $sewer: Boolean
    $internet: Boolean
  ) {
    createSearchProblem(
      input: {
        searchProblem: {
          searchId: $searchId
          userId: $userId
          addressSearch: $address
          telecomSearch: $internet
          sewerSearch: $sewer
          gasSearch: $gas
          electricSearch: $electric
          userStatement: $statement
          waterSearch: $water
        }
      }
    ) {
      searchProblem {
        userByUserId {
          firstName
        }
        userStatement
      }
    }
  }
`;

export default ReportProblem;
