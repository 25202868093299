import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import UserSubscription from "./gql/UserSubscription";
import UserQuery from "./gql/UserQuery";
import * as Dayjs from "dayjs";
import {
  Col,
  Card,
  Table,
  Form,
  Badge,
  Spinner,
  Toast,
  ButtonGroup,
} from "react-bootstrap";
import ResetPassword from "./ResetPassword";
import AdminToggle from "./AdminToggle";
import ActiveToggle from "./ActiveToggle";
import ApiKey from "./ApiKey";

const UsersTable = ({ user }) => {
  const { data, loading, error, subscribeToMore } = useQuery(UserQuery, {
    fetchPolicy: "network-only",
  });

  const [search, setSearch] = useState("");
  subscribeToMore({
    document: UserSubscription,
    updateQuery: (prev) => {
      return prev;
    },
  });

  if (loading) return <Spinner></Spinner>;

  if (error) return <Toast variant="danger">{error.message}</Toast>;

  if (data) {
    return (
      <Card className="h-100">
        <Card.Header className="d-flex flex-row justify-content-between align-items-center">
          <Col>
            <Card.Title>Users</Card.Title>
          </Col>
          <Col xs={4}>
            <Form.Control
              className="float-right"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="User Search"
              style={{
                height: "32px",
              }}
            />
          </Col>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th style={{ textAlign: "center" }}>Admin</th>
                <th style={{ textAlign: "center" }}>Joined</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="admin">
              {data.allUsers.nodes
                .filter((users) => {
                  const term = search.toLowerCase();
                  return (
                    users?.email.toLowerCase().includes(term) ||
                    users?.firstName.toLowerCase().includes(term) ||
                    users?.lastName.toLowerCase().includes(term)
                  );
                })
                .map((users, idx) => (
                  <tr key={idx} className="admin">
                    <td>
                      {users.firstName} {users.lastName}
                    </td>
                    <td>{users.email} </td>
                    <td style={{ textAlign: "center" }}>
                      {users.isAdmin ? (
                        <Badge
                          variant="accent"
                          sx={{ backgroundColor: "#1B1054" }}
                        >
                          Admin
                        </Badge>
                      ) : null}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {Dayjs(users.joinedOn).format("M/DD/YYYY")}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <ButtonGroup>
                        <ResetPassword user={users} />
                        <AdminToggle user={users} cuser={user} />
                        <ApiKey user={users} />
                        <ActiveToggle user={users} cuser={user} />
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  }
};

export default UsersTable;
