import { gql } from "@apollo/client";

const ShowApiKeyMutation = gql`
  mutation ShowApiKey($userId: UUID!) {
    showApiKey(input: { tuserId: $userId }) {
      key: string
    }
  }
`;

const CreateApiKeyMutation = gql`
  mutation CreateApiKey($userId: UUID!) {
    createApiKey(input: { tuserId: $userId }) {
      key: string
    }
  }
`;

const RotateApiKeyMutation = gql`
  mutation RotateApiKey($userId: UUID!) {
    rotateApiKey(input: { tuserId: $userId }) {
      key: string
    }
  }
`;

export { ShowApiKeyMutation, CreateApiKeyMutation, RotateApiKeyMutation };
