import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import LoginMutation from "./gql/LoginMutation";
import { Container, Col, Row, Form, Button, Card } from "react-bootstrap";
import { IoWarning } from "react-icons/io5";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();

  const [login] = useMutation(LoginMutation);

  if (localStorage.getItem("ssre-token") !== null) {
    localStorage.removeItem("ssre-token");
  }

  const handleSubmit = async (e) => {
    if (localStorage.getItem("ssre-token") !== null) {
      localStorage.removeItem("ssre-token");
    }

    e.preventDefault();

    try {
      const response = await login({
        variables: {
          email: email?.toLowerCase(),
          password: password,
        },
      });

      if (!response.data.authenticate.jwt) {
        setErrorMessage("Please Check Email and Password");
        return;
      } else {
        localStorage.setItem("ssre-token", response.data.authenticate.jwt);
        navigate("/password-update");
      }
      return;
    } catch (error) {
      console.error(error);
      setErrorMessage("There was an error authenticating.");
      return;
    }
  };

  return (
    <Container
      fluid
      style={{
        fontFamily: "sans-serif",
        color: "#95057D",
        width: "100vw",
        height: "100vh",
        backgroundImage:
          "linear-gradient(to right, rgba(27,16,84,1), rgba(149, 5, 125,1))",
        //backgroundColor: "rgba(27,16,84,.5)",
      }}
    >
      <Row
        className="justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <Col xs={12} md={6} lg={4} xl={2}>
          <Card className="border-0">
            <Card.Img
              variant="top"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              src="./logo.jpg"
            />
            <Card.Body>
              <Form id="loginForm" onSubmit={handleSubmit}>
                <Form.Group className="my-2">
                  <Form.Label htmlFor="email">Email</Form.Label>
                  <Form.Control
                    required
                    style={{
                      height: "36px",
                      border: errorMessage
                        ? "4px solid rgba(220, 10, 10, .75)"
                        : "1px solid rgba(149, 5, 125, .5)",
                      ":focus": {
                        outline: "none",
                        border: "4px solid rgba(149, 5, 125, .5)",
                      },
                    }}
                    name="email"
                    id="email"
                    type="text"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    mb={3}
                  />
                </Form.Group>
                <Form.Group className="my-2">
                  <Form.Label htmlFor="password">Password</Form.Label>
                  <Form.Control
                    required
                    style={{
                      height: "36px",
                      border: errorMessage
                        ? "4px solid rgba(220, 10, 10, .75)"
                        : "1px solid rgba(149, 5, 125, .5)",
                      ":focus": {
                        outline: "none",
                        border: "4px solid rgba(149, 5, 125, .5)",
                      },
                    }}
                    name="password"
                    id="password"
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    mb={3}
                  />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Row>
                <Col xs={8}>
                  <IoWarning
                    style={{
                      display: errorMessage ? "flex" : "none",
                      color: "#95057D",
                    }}
                  />{" "}
                  {errorMessage}
                </Col>
                <Col xs={3}>
                  <Button
                    className="float-right"
                    type="submit"
                    form="loginForm"
                    style={{ backgroundColor: "primary" }}
                  >
                    Login
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
