import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import AddUserMutation from "./gql/AddUserMutation";

const AddUser = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [admin, setAdmin] = useState(false);
  const [userError, setUserError] = useState(false);

  const [addUser] = useMutation(AddUserMutation);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await addUser({
        variables: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          admin: admin,
        },
      });

      if (!response.data.addUser.user.appId) {
        setUserError(true);
        return;
      }

      e.target.reset();
    } catch (error) {
      setUserError(true);
      console.error(error);
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Add User</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form id="add-user-form" onSubmit={handleSubmit} autoComplete="off">
          <Form.Group className="mb-3">
            <Form.Control
              autoComplete="new-password"
              mr={1}
              type="text"
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              autoComplete="new-password"
              mr={1}
              type="text"
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              autoComplete="off"
              mr={1}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="New Email"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              autoComplete="new-password"
              mr={1}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="New Password"
            />
          </Form.Group>
          <Row className="d-flex flex-row justify-content-between align-items-center">
            <Col xs={6}>
              <Form.Check
                type="switch"
                label="Admin"
                onChange={(e) => {
                  e.target.checked ? setAdmin(true) : setAdmin(false);
                }}
              />
            </Col>
            <Col xs={6}>
              <Button size="sm" type="submit" form="add-user-form">
                Add
              </Button>
            </Col>
          </Row>
        </Form>
        <Row>
          {userError && <p>An error occured while adding a new user.</p>}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AddUser;
