import React from "react";
import { useMutation } from "@apollo/client";
import ActiveUserMutation from "./gql/ActiveUserMutation";
import { Button } from "react-bootstrap";
import { FaUserMinus, FaUserPlus } from "react-icons/fa";

const ActiveToggle = ({ user, cuser }) => {
  const [activeToggle] = useMutation(ActiveUserMutation);
  const handleClick = async () => {
    await activeToggle({
      variables: { userId: user.appId, active: !user.isActive },
    });
  };
  return (
    <Button
      size="sm"
      title={user.isActive ? "Deactivate User" : "Activate User"}
      disabled={user.appId === cuser.appId}
      style={{
        backgroundColor: user.isActive ? "tomato" : "green",
        color: "white",
      }}
      onClick={() => handleClick()}
    >
      {user.isActive ? <FaUserMinus /> : <FaUserPlus />}
    </Button>
  );
};

export default ActiveToggle;
