import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
  split,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { setContext } from "@apollo/client/link/context";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "./index.css";
import "./custom.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("ssre-token");
  // return the headers to the context so httpLink can read them
  if (token) {
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null,
      },
    };
  } else {
    return headers;
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL_SERVER,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GQL_WS_SERVER,
    reconnect: true,
    connectionParams: () => ({
      Authorization: localStorage.getItem("ssre-token")
        ? `Bearer ${localStorage.getItem("ssre-token")}`
        : null,
    }),
  })
);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  credentials: "include",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
