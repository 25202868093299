import { gql } from "@apollo/client";

const UtilityQuery = gql`
  query utilityQuery($searchId: UUID!, $lat: Float!, $long: Float!) {
    electricByLocation(tx: $long, ty: $lat, tsearch: $searchId) {
      nodes {
        searchId
        rec {
          companyName
          companyType
          ogcFid
        }
      }
      totalCount
    }
    gasByLocation(tx: $long, ty: $lat, tsearch: $searchId) {
      nodes {
        searchId
        rec {
          companyName
          companyType
          ogcFid
        }
      }
      totalCount
    }
    waterByLocation(tx: $long, ty: $lat, tsearch: $searchId) {
      nodes {
        searchId
        rec {
          companyName
          ogcFid
          companyType
        }
      }
      totalCount
    }
    sewerByLocation(tx: $long, ty: $lat, tsearch: $searchId) {
      nodes {
        searchId
        rec {
          companyName
          companyType
          ogcFid
        }
      }
      totalCount
    }
    mudsByLocation(tx: $long, ty: $lat, tsearch: $searchId) {
      nodes {
        searchId
        rec {
          companyName
          companyType
          ogcFid
        }
      }
      totalCount
    }
    sudsByLocation(tx: $long, ty: $lat, tsearch: $searchId) {
      nodes {
        searchId
        rec {
          companyName
          companyType
          ogcFid
        }
      }
      totalCount
    }
    telecomFiberByLocation(tx: $long, ty: $lat, tsearch: $searchId) {
      nodes {
        searchId
        ogcFid
        business
        companyName
        companyType
        lowLatency
        maxDownload
        maxUpload
        residential
        technology
      }
      totalCount
    }
    telecomCableByLocation(tx: $long, ty: $lat, tsearch: $searchId) {
      nodes {
        searchId
        companyName
        companyType
        technology
        maxUpload
        maxDownload
        residential
        business
        ogcFid
        lowLatency
      }
      totalCount
    }
    telecomCopperByLocation(tx: $long, ty: $lat, tsearch: $searchId) {
      nodes {
        searchId

        business
        companyName
        companyType
        lowLatency
        maxDownload
        maxUpload
        residential
        ogcFid
        technology
      }
      totalCount
    }
    telecomLfwByLocation(tx: $long, ty: $lat, tsearch: $searchId) {
      nodes {
        searchId
        business
        residential
        companyName
        lowLatency
        maxDownload
        maxUpload
        ogcFid
        technology
      }
      totalCount
    }
    telecomUfwByLocation(tx: $long, ty: $lat, tsearch: $searchId) {
      nodes {
        searchId
        business
        residential
        companyName
        lowLatency
        maxDownload
        maxUpload
        ogcFid
        technology
      }
      totalCount
    }
    telecomOtherByLocation(tx: $long, ty: $lat, tsearch: $searchId) {
      nodes {
        searchId
        business
        residential
        companyName
        lowLatency
        maxDownload
        maxUpload
        ogcFid
        technology
      }
      totalCount
    }
  }
`;

export default UtilityQuery;

/*telecomGsoByLocation(tx: $long, ty: $lat, tsearch: $searchId) {
      nodes {
        searchId
        business
        companyName
        lowLatency
        maxDownload
        maxUpload
        ogcFid
        residential
        technology
      }
      totalCount
    }*/
