import { gql } from "@apollo/client";

const CurrentUserSubscription = gql`
  subscription UserSubscription($topic: String!) {
    listen(topic: $topic) {
      query {
        currentUser {
          appId
          email
          firstName
          lastName
          changePassword
          isActive
          isAdmin
          joinedOn
          apiToken
        }
      }
    }
  }
`;

export default CurrentUserSubscription;
