import React from "react";
import { useMutation } from "@apollo/client";
import AdminToggleMutation from "./gql/AdminToggleMutation";
import { Button } from "react-bootstrap";
import { FaUser, FaUserTie } from "react-icons/fa";

const AdminToggle = ({ user, cuser }) => {
  const [adminToggle] = useMutation(AdminToggleMutation);
  const handleClick = async () => {
    await adminToggle({
      variables: { userId: user.appId, admin: !user.isAdmin },
    });
  };
  return (
    <Button
      size="sm"
      title={user.isAdmin ? "Demote User" : "Make Admin"}
      disabled={user.appId === cuser.appId}
      style={{
        backgroundColor: "blue",
        color: "white",
      }}
      onClick={() => handleClick()}
    >
      {user.isAdmin ? <FaUser /> : <FaUserTie />}
    </Button>
  );
};

export default AdminToggle;
