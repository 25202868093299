import React, { useState, useEffect } from "react";
import Header from "../Layout/Navigation/Header";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import CurrentUser from "../Authentication/gql/CurrentUser";
import Navigation from "../Layout/Navigation/Navigation";
import CurrentUserSubscription from "../Authentication/gql/CurrentUserSubscription";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import * as Dayjs from "dayjs";
import { BsPinMap } from "react-icons/bs";
import { SearchesQuery } from "./gql/SearchesQueries";
import ProblemReport from "./ProblemReports";

const Searches = () => {
  const navigate = useNavigate();

  const date = new Date();

  const initStartDate = Dayjs(date.setDate(date.getDate())).format(
    "YYYY-MM-DD"
  );
  const initEndDate = Dayjs(date.setDate(date.getDate() + 1)).format(
    "YYYY-MM-DD"
  );

  const [appId, setAppId] = useState("");
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const [searchData, setSearchData] = useState([]);

  if (localStorage.getItem("ssre-token") === null) navigate("/login");

  const { loading, error, data, subscribeToMore } = useQuery(CurrentUser, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  subscribeToMore({
    document: CurrentUserSubscription,
    variables: { topic: `user_updates:${appId}` },
    updateQuery: () => {
      return true;
    },
  });

  useEffect(() => {
    setAppId(data?.currentUser?.appId);
  }, [data]);

  const [searches] = useLazyQuery(SearchesQuery);

  const handleSearchesSearch = async () => {
    const results = await searches({
      variables: { startDate: startDate, endDate: endDate },
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    });

    setSearchData(results.data.allUserSearches.nodes);
  };

  if (loading) return;
  if (error) navigate("/login");

  if (data.currentUser) {
    return data.currentUser.isAdmin ? (
      <>
        <Header user={data.currentUser} />
        <Navigation user={data.currentUser} />
        <Container>
          <Row className="m-2 g-4">
            <Col xs={12}>
              <Card>
                <Card.Header>
                  <Card.Title>Searches and Challenges</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row
                    as={Form}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Col xs={5}>
                      <Form.Group as={Row}>
                        <Form.Label
                          column
                          sm={4}
                          style={{ textAlign: "right" }}
                        >
                          Start Date
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            type="date"
                            aria-label="start-date"
                            max={endDate}
                            value={startDate}
                            className="mx-0 "
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={5}>
                      <Form.Group as={Row}>
                        <Form.Label
                          column
                          sm={4}
                          style={{ textAlign: "right" }}
                        >
                          End Date
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            type="date"
                            aria-label="end-date"
                            value={endDate}
                            min={startDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xs={2}>
                      <Button
                        size="sm"
                        className="ml-4 pl-4"
                        onClick={() => handleSearchesSearch()}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <Table
                      striped
                      bordered
                      size="sm"
                      style={{ fontSize: "10px" }}
                    >
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>Date</th>
                          <th style={{ textAlign: "center" }}>Map</th>
                          <th style={{ textAlign: "center" }}>
                            Search Address
                          </th>
                          <th style={{ textAlign: "center" }}>Address</th>
                          <th style={{ textAlign: "center" }}>Lat/Long</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchData.map((d) => (
                          <tr key={d.id}>
                            <td>
                              {Dayjs(d.lastSearchedOn).format(
                                "MM/DD/YYYY h:mm"
                              )}
                            </td>
                            <td style={{ textAlign: "center", margin: 0 }}>
                              {d.reverseSearch ? <BsPinMap /> : <></>}
                            </td>
                            <td>{d.searchAddress}</td>
                            <td>{d.fmtAddress}</td>
                            <td>
                              {d.latitude}, {d.longitude}
                            </td>
                            <td>
                              <ProblemReport problems={d.problems.nodes} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    ) : (
      navigate("/")
    );
  }
};

export default Searches;
