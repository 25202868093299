import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./Components/Layout/Main";
import Login from "./Components/Authentication/Login";
import NewPassword from "./Components/Authentication/NewPassword";
import Admin from "./Components/Admin/Main";
import Searches from "./Components/Admin/Searches";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" index element={<Main />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/password-update" element={<NewPassword />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/admin/searches" element={<Searches />} />
      </Routes>
    </div>
  );
}

export default App;
