import React from "react";
import { Card, Table } from "react-bootstrap";
import ProblemReport from "./ProblemReport";

const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

const StandardTable = ({ heading, User, data, searchId }) => {
  return (
    <Card className="p-0 m-0">
      <Card.Header className="d-flex flex-row justify-content-between align-items-center">
        <Card.Title className="my-0">{heading}</Card.Title>
        <ProblemReport area={heading} searchId={searchId} User={User} />
      </Card.Header>
      <Card.Body className="px-2">
        {data.length > 0 ? (
          <Table className="table-borderless" hover size="sm">
            <tbody>
              {data
                .filter(
                  (x, i) =>
                    i ===
                    data.findIndex(
                      (elem) => elem.rec.companyName === x.rec.companyName
                    )
                )
                .map((e) => {
                  return (
                    <tr key={e.rec.ogcFid}>
                      <td>{toTitleCase(e.rec.companyName)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        ) : (
          <Card.Subtitle>
            No {heading} data available at this location.
          </Card.Subtitle>
        )}
      </Card.Body>
    </Card>
  );
};

export default StandardTable;
