import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NewPasswordMutation from "./gql/NewPasswordMutation";
import { useQuery, useMutation } from "@apollo/client";
import CurrentUser from "./gql/CurrentUser";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { IoWarning } from "react-icons/io5";

const NewPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [valid, setValid] = useState(false);

  const { loading, error, data } = useQuery(CurrentUser);
  const [newPassword] = useMutation(NewPasswordMutation);

  if (localStorage.getItem("ssre-token") === null) navigate("/login");

  useEffect(() => {
    if (data?.currentUser) {
      if (!data.currentUser.changePassword) navigate("/");
      setEmail(data?.currentUser?.email);
    }
  }, [data, navigate]);

  if (loading) return;
  if (error) navigate("/login");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkPassword !== password) {
      setValid(true);
      setErrorMessage("Passwords Do Not Match");
      return;
    }

    try {
      const response = await newPassword({
        variables: {
          password: password,
        },
      });

      if (!response.data.newPassword.boolean) {
        setErrorMessage("New Password Not Accepted");
      }

      navigate("/");
    } catch (error) {
      console.error(error);
      setErrorMessage("There was an error authenticating.");
    }
  };

  if (data) {
    return (
      <Container
        fluid
        style={{
          fontFamily: "sans-serif",
          color: "#95057D",
          width: "100vw",
          height: "100vh",
          backgroundImage:
            "linear-gradient(to right, rgba(27,16,84,1), rgba(149, 5, 125,1))",
          //backgroundColor: "rgba(27,16,84,.5)",
        }}
      >
        <Row
          className="justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <Col xs={12} md={6} lg={4} xl={2}>
            <Card className="border-0">
              <Card.Img
                variant="top"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                src="./logo.jpg"
              />
              <Card.Body>
                <Form onSubmit={handleSubmit} id="newPasswordForm">
                  <Card.Header>
                    <Card.Subtitle>
                      There was a password reset request for {email}. Please
                      enter a new password.
                    </Card.Subtitle>
                  </Card.Header>
                  <Form.Group className="my-2">
                    <Form.Label htmlFor="password">New Passsword</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      name="password"
                      placeholder="New Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      sx={{
                        height: "36px",
                        border: errorMessage
                          ? "4px solid rgba(220, 10, 10, .75)"
                          : "1px solid rgba(149, 5, 125, .5)",
                        ":focus": {
                          outline: "none",
                          border: "4px solid rgba(149, 5, 125, .5)",
                        },
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="confirmPassword">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      required
                      isInvalid={valid}
                      sx={{
                        height: "36px",
                        border: errorMessage
                          ? "4px solid rgba(220, 10, 10, .75)"
                          : "1px solid rgba(149, 5, 125, .5)",
                        ":focus": {
                          outline: "none",
                          border: "4px solid rgba(149, 5, 125, .5)",
                        },
                      }}
                      onChange={(e) => {
                        setCheckPassword(e.target.value);
                      }}
                      value={checkPassword}
                    />
                  </Form.Group>
                </Form>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col xs={8}>
                    <IoWarning
                      style={{
                        display: errorMessage ? "inline-block" : "none",
                        color: "#95057D",
                      }}
                    />{" "}
                    {errorMessage}
                  </Col>
                  <Col xs={3}>
                    <Button
                      className="float-right"
                      type="submit"
                      form="newPasswordForm"
                      style={{ backgroundColor: "primary" }}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default NewPassword;
