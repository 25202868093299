import React from "react";
import ProblemReport from "./ProblemReport";
import { Col, Row, Card } from "react-bootstrap";

const InfoHeader = ({ address, User, searchId }) => {
  let partA;
  let partB;
  if (address && address !== "") {
    const renderAddress = address.split(",");
    partA = renderAddress[0];
    const [, ...rest] = renderAddress;
    partB = rest.concat(" ");
  }

  if (address) {
    return (
      <Card className="p-0">
        <Card.Header className="d-flex flex-row justify-content-between align-items-center">
          <Card.Title className="my-0">Selected Location</Card.Title>
          <ProblemReport area={"Address"} User={User} searchId={searchId} />
        </Card.Header>
        <Card.Body>
          <Row style={{ width: "100%" }}>
            <Col xs={10}>
              <Card.Text className="text-primary">
                {partA ? partA + "," : ""}
                <br></br>
                {partB ? partB : ""}
              </Card.Text>
            </Col>
            <Col xs={2}></Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
};

export default InfoHeader;
