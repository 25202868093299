import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Badge, Modal, Form } from "react-bootstrap";
import ReportProblem from "./gql/ProblemReportMutation";

const ProblemReport = ({ area, User, searchId }) => {
  const selectedArea = area.toLowerCase();
  const userId = User?.appId;
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState();
  const [description, setDescription] = useState();
  const [address, setAddress] = useState(selectedArea === "address");
  const [electric, setElectric] = useState(selectedArea === "electric");
  const [gas, setGas] = useState(selectedArea === "gas");
  const [water, setWater] = useState(selectedArea === "water");
  const [sewer, setSewer] = useState(selectedArea === "sewer");
  const [internet, setInternet] = useState(selectedArea === "internet");

  const [reportProblem] = useMutation(ReportProblem);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await reportProblem({
        variables: {
          searchId: searchId,
          userId: userId,
          statement: description,
          address: address,
          electric: electric,
          gas: gas,
          water: water,
          sewer: sewer,
          internet: internet,
        },
      });

      if (!response.data.createSearchProblem.searchProblem.userStatement) {
        setMessage(
          "There was a problem sending your challenge report. We're sorry."
        );
        e.target.reset();
        setTimeout(() => {
          setShow(false);
          setMessage();
        }, 10000);
      }

      setMessage(response.data.createSearchProblem.searchProblem.userStatement);
      e.target.reset();
      setTimeout(() => {
        setShow(false);
        setMessage();
      }, 30000);
    } catch (error) {
      setMessage(
        "There was a problem sending your challenge report. We're sorry."
      );
      console.error(error);
      e.target.reset();
      setTimeout(() => {
        setShow(false);
        setMessage();
      }, 10000);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        centered
        onHide={() => {
          setShow(!show);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{area} Challenge</Modal.Title>
        </Modal.Header>
        {message ? (
          <Modal.Body>
            <Modal.Title>Thank You For Your Feedback</Modal.Title>
            <p>Your Feedback:</p>
            <p>{message}</p>
          </Modal.Body>
        ) : (
          <Modal.Body>
            Please describe the issue that you experienced with the{" "}
            {selectedArea === "address search."
              ? selectedArea
              : `${selectedArea} providers.`}
            <Form id="problemForm" onSubmit={handleSubmit}>
              <Form.Group className="my-2">
                <Form.Control
                  className="mt-3"
                  as="textarea"
                  type="textarea"
                  placeholder="Please Enter a Description"
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></Form.Control>
                <p
                  style={{
                    marginLeft: "10px",
                    fontSize: ".8rem",
                    fontWeight: "bold",
                    color: "#666",
                  }}
                >
                  {selectedArea === "address"
                    ? "Hint: If you are unhappy with the location that we found, you can also click the location you want on the map."
                    : null}
                </p>
              </Form.Group>
              <Modal.Title>Were there any other problems?</Modal.Title>
              <Form.Group className="my-2 mx-4">
                <Form.Check
                  type="switch"
                  label="Address"
                  defaultChecked={selectedArea === "address"}
                  readOnly={selectedArea === "address"}
                  onChange={(e) => setAddress(e.target.checked)}
                ></Form.Check>
                <Form.Check
                  type="switch"
                  label="Electric Providers"
                  onChange={(e) => setElectric(e.target.checked)}
                  defaultChecked={selectedArea === "electric"}
                  readOnly={selectedArea === "electric"}
                ></Form.Check>
                <Form.Check
                  type="switch"
                  label="Gas Providers"
                  defaultChecked={selectedArea === "gas"}
                  readOnly={selectedArea === "gas"}
                  onChange={(e) => setGas(e.target.checked)}
                ></Form.Check>
                <Form.Check
                  type="switch"
                  label="Water Providers"
                  defaultChecked={selectedArea === "water"}
                  readOnly={selectedArea === "water"}
                  onChange={(e) => setWater(e.target.checked)}
                ></Form.Check>
                <Form.Check
                  type="switch"
                  label="Sewer Providers"
                  defaultChecked={selectedArea === "sewer"}
                  readOnly={selectedArea === "sewer"}
                  onChange={(e) => setSewer(e.target.checked)}
                ></Form.Check>
                <Form.Check
                  type="switch"
                  label="Internet Providers"
                  defaultChecked={selectedArea === "internet"}
                  readOnly={selectedArea === "internet"}
                  onChange={(e) => setInternet(e.target.checked)}
                ></Form.Check>
              </Form.Group>
            </Form>
          </Modal.Body>
        )}
        <Modal.Footer>
          {!message && (
            <Button
              className="float-right"
              type="submit"
              form="problemForm"
              style={{ backgroundColor: "primary" }}
            >
              Send Report
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Badge
        as={Button}
        className="align-self-center float-right"
        onClick={() => setShow(true)}
      >
        Challenge
      </Badge>
    </>
  );
};

export default ProblemReport;
