import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Row,
  Col,
  Offcanvas,
  Button,
  Container,
  Spinner,
} from "react-bootstrap";
import InfoHeader from "./InfoHeader";
import UtilityQuery from "./gql/UtilityQueries";
import StandardTable from "./StandardTable";
import InternetTable from "./InternetTable";

const Information = ({
  address,
  coords,
  searchId,
  User,
  setAddress,
  setCoords,
  setSearchId,
}) => {
  const latitude = coords?.[0];
  const longitude = coords?.[1];
  const [gas, setGas] = useState([]);
  const [electric, setElectric] = useState([]);
  const [water, setWater] = useState([]);
  const [sewer, setSewer] = useState([]);
  const [suds, setSuds] = useState([]);
  const [muds, setMuds] = useState([]);
  const [copper, setCopper] = useState([]);
  const [cable, setCable] = useState([]);
  const [fiber, setFiber] = useState([]);
  //const [gso, setGso] = useState([]);
  const [lfw, setLfw] = useState([]);
  const [ufw, setUfw] = useState([]);
  const [other, setOther] = useState([]);

  const [information, { called, loading, data }] = useLazyQuery(UtilityQuery);

  useEffect(() => {
    information({
      variables: {
        lat: latitude,
        long: longitude,
        searchId: searchId,
      },
    });
  }, [information, latitude, longitude, searchId]);

  useEffect(() => {
    if (data) {
      setGas(data.gasByLocation.nodes);
      setElectric(data.electricByLocation.nodes);
      setWater(data.waterByLocation.nodes);
      setSewer(data.sewerByLocation.nodes);
      setSuds(data.sudsByLocation.nodes);
      setMuds(data.mudsByLocation.nodes);
      setCopper(data.telecomCopperByLocation.nodes);
      setCable(data.telecomCableByLocation.nodes);
      setFiber(data.telecomFiberByLocation.nodes);
      //setGso(data.telecomGsoByLocation.nodes);
      setLfw(data.telecomLfwByLocation.nodes);
      setUfw(data.telecomUfwByLocation.nodes);
      setOther(data.telecomOtherByLocation.nodes);

      // Show the off-canvas modal
      handleShow();
    }
  }, [data]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (loading)
    return (
      <Container className="d-flex flex-column justify-content-center align-items-center h-100 mt-2 mx-0">
        <Spinner
          variant="primary"
          animation="border"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      </Container>
    );

  if (called && data) {
    return (
      <Container className="m-0 p-0">
        <Row>
          <Col className="m-1 p-0">
            <Button
              variant="primary"
              className="fixed-bottom translate-middle-y m-3 d-block d-sm-none"
              onClick={handleShow}
            >
              Show Details
            </Button>
            <Container className="m-0 p-0 overflow-auto">
              <Col
                className="d-none d-sm-block"
                style={{
                  position: "absolute",
                  height: "calc(100vh - 72px - 44px)",
                  overflowY: "auto",
                }}
              >
                <Row
                  className="m-2"
                  style={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                  }}
                >
                  <Col
                    className="my-2 p-1 mx-0"
                    style={{
                      fontSize: "1.2em",
                      height: "100%",
                      overflowY: "auto",
                    }}
                  >
                    <InfoHeader
                      address={address}
                      User={User}
                      searchId={searchId}
                      setAddress={setAddress}
                      setCoords={setCoords}
                      setSearchId={setSearchId}
                    />
                  </Col>
                </Row>

                <Row
                  className="m-2"
                  style={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                  }}
                >
                  <Col
                    className="mb-2 p-1 mx-0"
                    style={{
                      fontSize: "1.2em",
                      height: "100%",
                      overflowY: "auto",
                    }}
                  >
                    <StandardTable
                      heading={"Electric"}
                      User={User}
                      data={electric}
                      searchId={searchId}
                    />
                  </Col>
                </Row>
                <Row
                  className="m-2"
                  style={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                  }}
                >
                  <Col
                    className="mb-2 p-1 mx-0"
                    style={{
                      fontSize: "1.2em",
                      height: "100%",
                      overflowY: "auto",
                    }}
                  >
                    <StandardTable
                      heading={"Gas"}
                      User={User}
                      data={gas}
                      searchId={searchId}
                    />
                  </Col>
                </Row>
                <Row
                  className="m-2"
                  style={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                  }}
                >
                  <Col
                    className="mb-2 p-1 mx-0"
                    style={{
                      fontSize: "1.2em",
                      height: "100%",
                      overflowY: "auto",
                    }}
                  >
                    <StandardTable
                      heading={"Water"}
                      User={User}
                      data={[...water, ...suds, ...muds]}
                      searchId={searchId}
                    />
                  </Col>
                </Row>
                <Row
                  className="m-2"
                  style={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                  }}
                >
                  <Col
                    className="mb-2 p-1 mx-0"
                    style={{
                      fontSize: "1.2em",
                      height: "100%",
                      overflowY: "auto",
                    }}
                  >
                    <StandardTable
                      heading={"Sewer"}
                      User={User}
                      data={sewer}
                      searchId={searchId}
                    />
                  </Col>
                </Row>
                <Row
                  className="m-2"
                  style={{
                    fontSize: ".8em",
                    fontWeight: "bold",
                  }}
                >
                  <Col
                    className="mb-2 p-1 mx-0"
                    style={{
                      fontSize: "1.2em",
                      height: "100%",
                      overflowY: "auto",
                    }}
                  >
                    <InternetTable
                      heading={"Internet"}
                      User={User}
                      data={[
                        ...fiber,
                        ...cable,
                        ...copper,
                        //...gso,
                        ...lfw,
                        ...ufw,
                        ...other,
                      ]}
                      searchId={searchId}
                    />
                  </Col>
                </Row>
              </Col>
            </Container>
            {/* Off-canvas */}
            <Offcanvas
              className="d-block d-sm-none"
              style={{ width: "100%", overflowY: "auto" }}
              show={show}
              onHide={handleClose}
              placement="end"
              backdrop={false}
            >
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body>
                <Col
                  className="my-4 p-1 mx-0"
                  style={{
                    fontSize: "1.2em",
                    height: "100%",
                    overflowY: "auto",
                  }}
                >
                  <Row
                    className="m-2"
                    style={{
                      fontSize: ".8em",
                      fontWeight: "bold",
                    }}
                  >
                    <InfoHeader
                      address={address}
                      User={User}
                      searchId={searchId}
                      setAddress={setAddress}
                      setCoords={setCoords}
                      setSearchId={setSearchId}
                    />
                  </Row>
                  <Row
                    className="m-2"
                    style={{
                      fontSize: ".8em",
                      fontWeight: "bold",
                    }}
                  >
                    <StandardTable
                      heading={"Electric"}
                      User={User}
                      data={electric}
                      searchId={searchId}
                    />
                  </Row>
                  <Row
                    className="m-2"
                    style={{
                      fontSize: ".8em",
                      fontWeight: "bold",
                    }}
                  >
                    <StandardTable
                      heading={"Gas"}
                      User={User}
                      data={gas}
                      searchId={searchId}
                    />
                  </Row>
                  <Row
                    className="m-2"
                    style={{
                      fontSize: ".8em",
                      fontWeight: "bold",
                    }}
                  >
                    <StandardTable
                      heading={"Water"}
                      User={User}
                      data={[...water, ...suds, ...muds]}
                      searchId={searchId}
                    />
                  </Row>
                  <Row
                    className="m-2"
                    style={{
                      fontSize: ".8em",
                      fontWeight: "bold",
                    }}
                  >
                    <StandardTable
                      heading={"Sewer"}
                      User={User}
                      data={sewer}
                      searchId={searchId}
                    />
                  </Row>
                  <Row
                    className="m-2"
                    style={{
                      fontSize: ".8em",
                      fontWeight: "bold",
                    }}
                  >
                    <InternetTable
                      heading={"Internet"}
                      User={User}
                      data={[
                        ...fiber,
                        ...cable,
                        ...copper,
                        //...gso,
                        ...lfw,
                        ...ufw,
                        ...other,
                      ]}
                      searchId={searchId}
                    />
                  </Row>
                </Col>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
        </Row>
      </Container>
    );
  }

  return null;
};

export default Information;
