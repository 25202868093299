import React from "react";
import { Card, Table } from "react-bootstrap";
import ProblemReport from "./ProblemReport";

const InternetTable = ({ heading, User, data, searchId }) => {
  const toTitleCase = (str) => {
    return str
      ?.toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };

  return (
    <Card className="p-0">
      <Card.Header className="d-flex flex-row justify-content-between align-items-center">
        <Card.Title className="my-0">{heading}</Card.Title>
        <ProblemReport area={heading} User={User} searchId={searchId} />
      </Card.Header>
      <Card.Body className="px-1">
        {data.length > 0 ? (
          <Table
            style={{
              marginLeft: "2px",
              marginRight: "2px",
              fontFamily: "sans-serif",
              fontSize: "13px",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Technology</th>
                <th>Down</th>
                <th>Up</th>
              </tr>
            </thead>
            <tbody>
              {data
                .filter((x) => {
                  return x.residential === true;
                })
                .map((e) => {
                  return (
                    <tr key={e.ogcFid}>
                      <td>{e.companyName}</td>
                      <td>
                        {toTitleCase(e.companyType) ||
                          toTitleCase(e.technology)}
                      </td>
                      <td>{e.maxDownload}</td>
                      <td>{e.maxUpload}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        ) : (
          <Card.Subtitle>
            No {heading} data available at this location.
          </Card.Subtitle>
        )}
      </Card.Body>
    </Card>
  );
};

export default InternetTable;
