import { gql } from "@apollo/client";

const SearchesQuery = gql`
  query Searches($startDate: Datetime, $endDate: Datetime) {
    allUserSearches(
      filter: {
        lastSearchedOn: {
          greaterThanOrEqualTo: $startDate
          lessThanOrEqualTo: $endDate
        }
      }
      orderBy: LAST_SEARCHED_ON_DESC
    ) {
      nodes {
        city
        country
        fmtAddress
        id
        lastSearchedOn
        latitude
        longitude
        provider
        reverseSearch
        searchAddress
        searchConfidence
        searchLat
        searchLong
        streetName
        streetNumber
        userId
        uspsState
        zipcode
        appId
        problems: searchProblemsBySearchId(orderBy: CREATED_ON_DESC) {
          nodes {
            addressSearch
            createdOn
            electricSearch
            gasSearch
            id
            sewerSearch
            telecomSearch
            user: userByUserId {
              firstName
              lastName
              email
            }
            waterSearch
            userStatement
          }
        }
      }
    }
  }
`;

export { SearchesQuery };
