import { gql } from "@apollo/client";

const UserSubscription = gql`
  subscription UserSubscription {
    listen(topic: "users_update") {
      query {
        allUsers {
          nodes {
            id
            appId
            email
            firstName
            lastName
            isActive
            isAdmin
            joinedOn
          }
        }
      }
    }
  }
`;

export default UserSubscription;
