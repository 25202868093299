import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  CreateApiKeyMutation,
  RotateApiKeyMutation,
  ShowApiKeyMutation,
} from "./gql/ApiKeys";
import {
  Row,
  Col,
  Button,
  Form,
  ButtonGroup,
  InputGroup,
} from "react-bootstrap";
import { BsKeyFill, BsEyeFill, BsUnlockFill } from "react-icons/bs";
import { FiRotateCw } from "react-icons/fi";
import { MdClose } from "react-icons/md";

const ApiKey = ({ user }) => {
  const [show, setShow] = useState(false);
  const [apiKey, setApiKey] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [showApiKey] = useMutation(ShowApiKeyMutation);
  const [createtApiKey] = useMutation(CreateApiKeyMutation);
  const [rotateApiKey] = useMutation(RotateApiKeyMutation);
  const userId = user.appId;

  const handleCreate = async () => {
    try {
      const response = await createtApiKey({
        variables: { userId: userId },
      });

      if (!response.data.createApiKey.key) {
        setErrorMessage(true);
        return;
      }

      setApiKey(response.data.createApiKey.key);
    } catch (error) {
      setErrorMessage(true);
      return;
    }
  };

  const handleShow = async () => {
    try {
      const response = await showApiKey({
        variables: { userId: userId },
      });

      if (!response.data.showApiKey.key) {
        setErrorMessage(true);
        return;
      }

      setApiKey(response.data.showApiKey.key);
    } catch (error) {
      setErrorMessage(true);
      return;
    }
  };

  const handleRotate = async () => {
    try {
      const response = await rotateApiKey({
        variables: { userId: userId },
      });

      if (!response.data.rotateApiKey.key) {
        setErrorMessage(true);
        return;
      }

      setApiKey(response.data.rotateApiKey.key);
    } catch (error) {
      setErrorMessage(true);
      return;
    }
  };

  return (
    <>
      <Button
        title="API Key"
        variant="secondary"
        size="sm"
        style={{
          color: "white",
        }}
        onClick={() => {
          setApiKey();
          setErrorMessage(false);
          setShow(!show);
        }}
      >
        <BsKeyFill />
      </Button>
      <Row
        style={{
          zIndex: 999,
          position: "absolute",
          display: show ? "block" : "none",
          top: "32px",
          height: "32px",
          width: "375px",
        }}
      >
        <Col xs={12}>
          <InputGroup size="sm" className="mb-3 border-0">
            <Form.Control
              style={{ height: "32px" }}
              type="text"
              value={apiKey ? apiKey : ""}
              readOnly
            />
            <ButtonGroup>
              <Button
                title="Show API Key"
                size="sm"
                style={{
                  backgroundColor: "blue",
                }}
                onClick={() => handleShow()}
              >
                <BsEyeFill />
              </Button>
              <Button
                title="Create API Key"
                size="sm"
                style={{
                  backgroundColor: "green",
                }}
                onClick={() => handleCreate()}
              >
                <BsUnlockFill />
              </Button>
              <Button
                title="Generate new API Key"
                size="sm"
                style={{
                  backgroundColor: "yellow",
                }}
                onClick={() => handleRotate()}
              >
                <FiRotateCw />
              </Button>
              <Button
                title="Close"
                size="sm"
                onClick={() => {
                  setApiKey();
                  setErrorMessage(false);
                  setShow(false);
                }}
                style={{
                  backgroundColor: "red",
                }}
              >
                <MdClose />
              </Button>
            </ButtonGroup>
          </InputGroup>
        </Col>
        <Row>
          <Col>
            {errorMessage && (
              <p>There was a problem with this users API Key.</p>
            )}
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ApiKey;
