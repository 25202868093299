import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const Navigation = ({ user }) => {
  return (
    <Navbar
      variant="dark"
      bg="primary"
      className="px-4 mx-0"
      style={{ maxHeight: "44px" }}
    >
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Nav className="me-auto">
        <Nav.Link
          as={Link}
          to="/"
          style={{
            color: "#FFF",
          }}
        >
          Home
        </Nav.Link>
        <Nav.Link
          href="https://servicestationre.com/"
          target="_blank"
          rel="noreferrer"
          style={{
            color: "#FFF",
          }}
        >
          {" "}
          About
        </Nav.Link>
      </Nav>
      <Navbar.Collapse className="justify-content-end">
        {user?.isAdmin && (
          <Nav className="float-right">
            <Nav.Link
              as={Link}
              to="/admin"
              style={{
                color: "#FFF",
              }}
            >
              Administration
            </Nav.Link>

            <Nav.Link
              as={Link}
              to="/admin/searches"
              style={{
                color: "#FFF",
              }}
            >
              Searches
            </Nav.Link>
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
