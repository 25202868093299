import React, { useState, useEffect } from "react";
import AddUser from "./AddUser";
import Header from "../Layout/Navigation/Header";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import CurrentUser from "../Authentication/gql/CurrentUser";
import UsersTable from "./UsersTable";
import Navigation from "../Layout/Navigation/Navigation";
import CurrentUserSubscription from "../Authentication/gql/CurrentUserSubscription";

const Admin = () => {
  const navigate = useNavigate();

  const [appId, setAppId] = useState("");

  if (localStorage.getItem("ssre-token") === null) navigate("/login");
  const { loading, error, data, subscribeToMore } = useQuery(CurrentUser, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  subscribeToMore({
    document: CurrentUserSubscription,
    variables: { topic: `user_updates:${appId}` },
    updateQuery: () => {
      return true;
    },
  });

  useEffect(() => {
    setAppId(data?.currentUser?.appId);
  }, [data]);

  if (loading) return;
  if (error) navigate("/login");

  if (data.currentUser) {
    return data.currentUser.isAdmin ? (
      <>
        <Header user={data.currentUser} />
        <Navigation user={data.currentUser} />

        <Container>
          <Card className="p-0 my-4">
            <Card.Header>
              <Card.Title>User Administration</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={12} md={6} lg={3}>
                  <AddUser />
                </Col>
                <Col xs={12} md={6} lg={9}>
                  <UsersTable user={data.currentUser} />
                </Col>
                <Col
                  sx={{
                    flexDirection: "column",
                    width: "10%",
                  }}
                ></Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </>
    ) : (
      navigate("/")
    );
  }
};

export default Admin;
