import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import ResetPasswordMutation from "./gql/ResetPassowrdMutation";
import {
  Row,
  Col,
  Button,
  Form,
  ButtonGroup,
  InputGroup,
} from "react-bootstrap";
import { BsPersonFillLock, BsEyeFill } from "react-icons/bs";
import { MdOutlineLockReset, MdClose } from "react-icons/md";

const ResetPassword = ({ user }) => {
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [resetPassword] = useMutation(ResetPasswordMutation);

  const userId = user.appId;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await resetPassword({
        variables: { userId: userId, password: password },
      });

      if (!response.data.changePassword.boolean) {
        setErrorMessage(true);
        return;
      }
      setSuccessMessage(true);
      e.target.reset();
    } catch (error) {
      setErrorMessage(true);
      return;
    }
  };

  return (
    <>
      <Button
        title="Reset User Password"
        size="sm"
        style={{
          color: "white",
        }}
        onClick={() => setShow(!show)}
      >
        <BsPersonFillLock />
      </Button>
      <Row
        style={{
          zIndex: 999,
          position: "absolute",
          display: show ? "block" : "none",
          top: "32px",
          height: "32px",
          width: "300px",
        }}
      >
        <Col xs={12}>
          <Form onSubmit={handleSubmit} id="resetPasswordForm">
            <InputGroup size="sm" className="mb-3 border-0">
              <Form.Control
                style={{ height: "32px" }}
                required
                type={showPassword ? "text" : "password"}
                placeholder="New Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <ButtonGroup>
                <Button
                  size="sm"
                  form="resetPasswordForm"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    backgroundColor: "blue",
                  }}
                >
                  <BsEyeFill />
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  form="resetPasswordForm"
                  style={{
                    backgroundColor: "green",
                  }}
                >
                  <MdOutlineLockReset style={{}} />
                </Button>
                <Button
                  size="sm"
                  form="resetPasswordForm"
                  onClick={() => {
                    setPassword();
                    setSuccessMessage(false);
                    setErrorMessage(false);
                    setShow(false);
                  }}
                  style={{
                    backgroundColor: "red",
                  }}
                >
                  <MdClose />
                </Button>
              </ButtonGroup>
            </InputGroup>
          </Form>
        </Col>
        <Row>
          <Col>
            {errorMessage && (
              <p>There was a problem reseting this users password.</p>
            )}
            {successMessage && (
              <p>
                The users password was successfully changed for {user.firstName}{" "}
                {user.lastName}.
              </p>
            )}
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ResetPassword;
