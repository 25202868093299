import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import CurrentUser from "../Authentication/gql/CurrentUser";
import CurrentUserSubscription from "../Authentication/gql/CurrentUserSubscription";
import Header from "./Navigation/Header";
import Navigation from "./Navigation/Navigation";
import Information from "../Information/Information";
import AddressSearch from "./Search/AddressSearch";
import MapBoxMap from "../Map/MapBoxMap";

const Main = () => {
  const navigate = useNavigate();
  if (localStorage.getItem("ssre-token") === null) navigate("/login");
  const [coords, setCoords] = useState();
  const [address, setAddress] = useState("");
  const [appId, setAppId] = useState("");
  const [searchId, setSearchId] = useState();
  const [bounding, setBounding] = useState("0,0,0,0");
  const [centroid, setCentroid] = useState("0,0");

  const { loading, error, data, subscribeToMore } = useQuery(CurrentUser, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  subscribeToMore({
    document: CurrentUserSubscription,
    variables: { topic: `user_updates:${appId}` },
    updateQuery: () => {
      return true;
    },
  });

  useEffect(() => {
    if (localStorage.getItem("ssre-token") === null) navigate("/login");
  }, [navigate]);

  useEffect(() => {
    setAppId(data?.currentUser?.appId);
  }, [data]);

  if (error) {
    navigate("/login");
  }

  if (loading) return;

  if (data) {
    return (
      <Container fluid className="mx-0 gx-0 overflow-hidden">
        <Header user={data?.currentUser} />
        <Navigation user={data?.currentUser} />
        <Row
          className="p-0 m-0 gx-0"
          style={{
            height: "calc(100vh - 72px - 44px)",
            minHeight: "400px",
          }}
        >
          <Col
            className="m-0 p-0 g-0"
            xs={address ? 12 : 12}
            md={address ? 6 : 12}
            lg={address ? 9 : 12}
            style={{
              height: "100%",
              minHeight: "400px",
              overflow: "hidden",
            }}
          >
            <AddressSearch
              setBounding={setBounding}
              setCentroid={setCentroid}
              bounding={bounding}
              centroid={centroid}
              setAddress={setAddress}
              setCoords={setCoords}
              setSearchId={setSearchId}
              User={data?.currentUser}
            />

            <MapBoxMap
              coords={coords}
              setAddress={setAddress}
              setCoords={setCoords}
              setSearchId={setSearchId}
              searchId={searchId}
              User={data?.currentUser}
            />
          </Col>
          <Col
            className="p-0 m-0 gx-0"
            xs={address ? 12 : 0}
            md={address ? 6 : 0}
            lg={address ? 3 : 0}
            style={{
              display: address ? "block" : "none",
              boxShadow: "-8px 0px 10px 1px #aaaaaaaa",
              height: "100%",
              padding: 0,
            }}
          >
            <Information
              address={address}
              setAddress={setAddress}
              setCoords={setCoords}
              setSearchId={setSearchId}
              coords={coords}
              searchId={searchId}
              User={data?.currentUser}
            />
          </Col>
        </Row>
      </Container>
    );
  }
};

export default Main;
