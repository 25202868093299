import React, { useEffect, useState, useRef } from "react";
import { GeolocateControl, Map, Marker, NavigationControl } from "react-map-gl";

const MapBoxMap = ({ coords, setAddress, setCoords, setSearchId, User }) => {
  const initialLong = -98.5556199;
  const initialLat = 39.8097343;
  const initialZoom = 4;
  const [lng, setLng] = useState(initialLong);
  const [lat, setLat] = useState(initialLat);
  const [zoom, setZoom] = useState(initialZoom);
  const token = User?.apiToken;

  const mapRef = useRef();

  const handleClick = async (e) => {
    if (!mapRef.current) return;
    if (mapRef.current.getZoom() < 12) return;
    const latitude = e.lngLat.lat;
    const longitude = e.lngLat.lng;
    try {
      const url = new URL(
        "/reverse/geocode/geocodio",
        process.env.REACT_APP_API_SERVER
      );
      url.searchParams.append("lat", latitude);

      url.searchParams.append("long", longitude);

      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status < 200 || response.status >= 300) {
        return;
      }
      const json = await response.json();

      if (json?.address && json?.searchId) {
        setSearchId(json.searchId);
        setAddress(json.address);
        setCoords([e.lngLat.lat, e.lngLat.lng]);
      }

      return;
    } catch (error) {
      setCoords([e.lngLat.lat, e.lngLat.lng]);
      console.error(error);
    }
  };

  useEffect(() => {
    if (!mapRef.current) return;
    if (coords) {
      setLng(coords[1]);
      setLat(coords[0]);
      if (zoom < 16) {
        setZoom(16);
      }
    } else {
      setLng(initialLong);
      setLat(initialLat);
      setZoom(initialZoom);
    }
  }, [mapRef, coords, zoom, initialLat, initialLong, initialZoom]);

  useEffect(() => {
    if (!mapRef.current) return;
    mapRef.current.getMap().resize();
    mapRef.current
      .getMap()
      .flyTo({ center: [lng, lat], zoom: zoom, duration: 1000 });
  }, [mapRef, lat, lng, zoom]);

  return (
    <Map
      reuseMaps
      ref={mapRef}
      initialViewState={{
        longitude: lng,
        latitude: lat,
        zoom: zoom,
      }}
      mapStyle="mapbox://styles/mapbox/light-v9"
      onClick={(e) => handleClick(e)}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
    >
      <NavigationControl position="top-left" />
      <GeolocateControl position="top-left" />
      {coords && (
        <Marker longitude={lng} latitude={lat} anchor="center">
          <img alt="" src="./logo180.png" style={{ height: "38px" }}></img>
        </Marker>
      )}
    </Map>
  );
};

export default MapBoxMap;
