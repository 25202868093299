import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Alert,
  ListGroup,
  InputGroup,
} from "react-bootstrap";
import { AiOutlineWarning } from "react-icons/ai";
//import State from "./State";

const AddressSearch = ({
  setAddress,
  setCoords,
  setSearchId,
  User,
  //setBounding,
  //setCentroid,
  //bounding,
  //centroid,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [formError, setFormError] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [activeResultIndex, setActiveResultIndex] = useState(-1);
  const [searchSelected, setSearchSelected] = useState(false);
  //const [searchEnable, setSearchEnable] = useState(false);

  const token = User?.apiToken;

  const performAddressSearch = async (address) => {
    const url = new URL("/geocode/geocodio", process.env.REACT_APP_API_SERVER);
    url.searchParams.append("address", address);

    const response = await fetch(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    if (response.status < 200 || response.status >= 300) {
      throw new Error(
        "We had a problem retrieving your address location. Try adding more information."
      );
    }

    const json = await response.json();

    if (json?.matchedAddress && json?.coordinates) {
      setSearchId(json.searchId);
      setAddress(json.matchedAddress);
      setCoords(json.coordinates);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let searchAddress = searchQuery;

    if (searchAddress?.trim().length === 0) {
      setFormError("Please Enter an Address in the search bar");
      return;
    }
    if (searchQuery.length < 5) {
      setFormError("The address that you are searching for should be longer.");
      return;
    }

    try {
      await performAddressSearch(searchQuery);
      setFormError();
      e.target.reset();
    } catch (error) {
      setFormError("We had a problem retrieving your address information.");
      console.error(error);
    }
  };

  useEffect(() => {
    const getAutofill = async () => {
      const searchString = encodeURIComponent(searchQuery);
      const url = new URL(
        `/geocoding/v5/mapbox.places/${searchString}.json`,
        "https://api.mapbox.com"
      );

      url.searchParams.append(
        "access_token",
        process.env.REACT_APP_MAPBOX_TOKEN
      );

      url.searchParams.append("country", "us");
      url.searchParams.append("fuzzyMatch", false);
      url.searchParams.append("limit", 10);
      url.searchParams.append("types", "address");
      //url.searchParams.append("proximity", centroid);
      //url.searchParams.append("bbox", bounding);
      try {
        const response = await fetch(url);

        if (response.status < 200 || response.status >= 300) {
          setFormError("Search Suggestions are offline.");
        }
        const json = await response.json();
        if (json.features) {
          setSearchResults(json.features);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (searchQuery?.length === 0) {
      setSearchResults([]);
    }

    if (searchSelected) {
      setSearchResults([]);
    }

    if (
      !searchSelected &&
      searchQuery?.length >= 4 &&
      searchQuery?.length % 4 === 0
    ) {
      setTimeout(() => {
        getAutofill();
      }, 500);
    }
  }, [searchQuery, searchSelected]);

  const handleKeyDown = async (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      if (activeResultIndex > 0) {
        setActiveResultIndex(activeResultIndex - 1);
      }
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      if (activeResultIndex < searchResults.length - 1) {
        setActiveResultIndex(activeResultIndex + 1);
      }
    } else if (event.key === "Enter" && activeResultIndex !== -1) {
      const selectedResult = searchResults[activeResultIndex];
      const selectedAddress = selectedResult?.place_name.replace(
        ", United States",
        ""
      );

      setSearchQuery(selectedAddress);
      setSearchSelected(true);
      setSearchResults([]);
      setActiveResultIndex(-1);

      try {
        await performAddressSearch(selectedResult.place_name);
        setFormError();
      } catch (error) {
        setFormError("We had a problem retrieving your address information.");
        console.error(error);
      }
    }
  };

  const handleResultClick = async (index, e) => {
    const selectedResult = searchResults[index];
    setSearchQuery(selectedResult?.place_name.replace(", United States", ""));
    setSearchResults([]);
    setActiveResultIndex(-1);
    setSearchSelected(true);

    try {
      await performAddressSearch(selectedResult.place_name);

      setFormError();
    } catch (error) {
      setFormError("We had a problem retrieving your address information.");
      console.error(error);
    }
  };

  return (
    <Container>
      <Row
        className="flex justify-content-center"
        style={{ position: "relative", top: "25px", zIndex: 999 }}
      >
        <Col
          as="form"
          style={{
            width: "100%",
            zIndex: 999,
            position: "absolute",
            minWidth: "320px",
            maxWidth: "40%",
            backgroundColor: "rgba(255, 255, 255, .90)",
          }}
          onSubmit={(e) => handleSubmit(e)}
        >
          <Row
            style={{
              flexDirection: "row",
              minHeight: "42px",
              borderRadius: 4,
              boxShadow: formError
                ? "0 0 0 4px rgba(200, 0, 0, 0.6)"
                : "0 0 0 4px rgba(27, 16, 84, 0.3)",
            }}
          >
            <InputGroup className="p-0 m-0">
              <Form.Control
                as="input"
                type="search"
                autoComplete="off"
                py={2}
                style={{
                  height: "42px",
                  width: "75%",
                  backgroundColor: "rgba(255, 255, 255, .80)",
                  ":focus": {
                    outline: "none",
                  },
                  borderRadius: "4px",
                  border: 0,
                }}
                name="address"
                id="address"
                placeholder="Enter an address"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setSearchSelected(false);
                }}
                //disabled={!searchEnable}
                onKeyDown={handleKeyDown}
              />
              <input type="submit" hidden />
              {/* <State
                setBounding={setBounding}
                setCentroid={setCentroid}
                setSearchEnable={setSearchEnable}
                setSearchQuery={setSearchQuery}
              ></State> */}
            </InputGroup>
          </Row>
          <Row
            style={{
              padding: 0,
              display: searchResults.length > 1 ? "flex" : "none",
            }}
          >
            <Col className="m-0 p-0 border-radius-0">
              <ListGroup className="m-0 p-0 borderless">
                {!searchSelected &&
                  searchResults.map((s, idx) => (
                    <ListGroup.Item
                      key={idx}
                      tabIndex="0"
                      className="m-0 p-1 border-radius-0 borderless"
                      onClick={(e) => handleResultClick(idx, e)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleResultClick(idx);
                        }
                      }}
                      active={activeResultIndex === idx}
                    >
                      {s.place_name.replace(", United States", "")}
                      <input type="submit" hidden />
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Col>
          </Row>
          <Row
            style={{
              padding: 0,
              display: formError ? "flex" : "none",
            }}
          >
            <Alert
              className="px-2"
              variant="danger"
              style={{
                margin: 0,
                padding: 1,
                zIndex: 998,
                borderRadius: "0 0 8px 8px",
                fontSize: "1em",
              }}
            >
              <AiOutlineWarning /> {formError}
            </Alert>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AddressSearch;
