import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Navbar,
  Nav,
  Popover,
  OverlayTrigger,
  ListGroup,
} from "react-bootstrap";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";

const Header = ({ user }) => {
  const navigate = useNavigate();

  if (!user) {
    navigate("/login");
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header>{user?.email}</Popover.Header>
      <Popover.Body>
        <ListGroup>
          {user?.isAdmin && (
            <ListGroup.Item action to="/admin" as={Link}>
              Admin
            </ListGroup.Item>
          )}
          {user?.isAdmin && (
            <ListGroup.Item action to="/admin/searches" as={Link}>
              Searches
            </ListGroup.Item>
          )}
          <ListGroup.Item
            action
            onClick={() => {
              localStorage.removeItem("photo-token");
              navigate("/login");
            }}
          >
            Logout
          </ListGroup.Item>
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  return (
    <Navbar
      className="px-4 mx-0"
      variant="dark"
      bg="secondary"
      style={{ maxHeight: "72px" }}
    >
      <Navbar.Brand>
        <Container>
          <img
            alt="Service StationRE Logo"
            className="d-inline-block align-top"
            width={200}
            style={{
              maxWidth: "100%",
              maxHeight: "90%",
            }}
            src="./logo.jpg"
          />
        </Container>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="justify-content-end">
        <Nav className="mx-4 float-right">
          <Container>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popover}
            >
              <Avatar
                round
                fgColor="#fff"
                color="#95057d"
                name={user?.firstName}
                size="37"
              />
            </OverlayTrigger>
          </Container>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
